import React, { useState, useEffect } from "react";

import axios from "axios";
import parse from "html-react-parser";
import { useParams, useNavigate } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async"; // You might need to install this package

import useStyles from "./PostDetail.style";

const PostDetail = () => {
  const { slug } = useParams(); // Get post slug from route params
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_CMS_URL}/wp-json/wp/v2/posts?slug=${slug}&_embed`
      )
      .then((res) => {
        if (res.data && res.data.length > 0) {
          setPost(res.data[0]);
        } else {
          setError(new Error("Post not found"));
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [slug]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!post) return <p>No post found.</p>;

  const content = parse(post.content?.rendered || "");
  const featuredImage =
    post._embedded &&
    post._embedded["wp:featuredmedia"] &&
    post._embedded["wp:featuredmedia"][0].source_url;
  const isValidImageUrl = (url) => {
    return url && url.match(/^https?:\/\/.+\.(jpg|jpeg|png|gif|avif)$/i);
  };
  // Extract metadata
  const title = post.title?.rendered || "Untitled";
  const excerpt = post.excerpt?.rendered || "";
  // const date = new Date(post.date).toLocaleDateString();
  // const author = post._embedded?.author?.[0]?.name || "Unknown Author";
  const categories =
    post._embedded?.["wp:term"]?.[0]?.map((cat) => cat.name).join(", ") || "";
  const tags =
    post._embedded?.["wp:term"]?.[1]?.map((tag) => tag.name).join(", ") || "";

  return (
    <HelmetProvider>
      <div className={classes.postDetail}>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={excerpt} />
          <meta name="keywords" content={categories + ", " + tags} />
        </Helmet>

        <h1 className={classes.title}>{title}</h1>
        <div className={classes.metadata}>
          <p>Tags: {tags}</p>
        </div>
        {isValidImageUrl(featuredImage) && (
          <img
            className={classes.articleImage}
            alt="article header"
            src={featuredImage}
          />
        )}
        <div className={classes.content}>{content}</div>
        <button
          onClick={() => navigate("/blog")}
          className={classes.backButton}
        >
          Back to Articles
        </button>
      </div>
    </HelmetProvider>
  );
};

export default PostDetail;
