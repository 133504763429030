import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Deposit from "./components/User/Deposit";
import Header from "./components/Header";
import Footer from "./components/Footer";
import MyProfile from "./components/User/MyProfile";
import NotASubscriber from "./components/NotASubscriber";
import Sidebar from "./components/Sidebar";
import Lobby from "./container/Casino/Lobby";
import Transaction from "./components/User/Transaction";
import ResetPassword from "./components/User/ResetPassword";
import Withdraw from "./components/User/Withdraw";
// import BetHistory from "./components/User/BetHistory";
import ForgotPassword from "./components/ForgotPassword";
import VerifyEmail from "./components/VerifyEmail";
// import Bonus from "./components/User/Bonus";
// import Referral from "./components/User/Referral";
import Promotions from "./components/Promotions";
import Livecasino from "./components/Livecasino";
import Toast from "./container/Toast";
import ViewAll from "./container/Casino/ViewAll";
import IframeGame from "./container/Casino/IframeGame";
import Cms from "./components/Footer/Cms";
import AppSidebar from "./components/AppSidebar";
import HideAppSidebar from "./components/AppSidebar/HideAppSidebar";
import SportBook from "./container/SportsBook/Sports";
import MoreMarkets from "./container/SportsBook/MoreMarkets";
import SportBookLobby from "./container/SportsBook/Lobby";
import Mybets from "./container/SportsBook/Mybets";
import Favorite from "./container/SportsBook/Favorites";
import MatchResults from "./container/SportsBook/MatchResults";
// import Favorites from "./container/Casino/Favorites";
import session from "./utils/session";
import Blog from "./components/Blog";
import PostDetail from "./components/Blog/PostDetails";

const appRoutes = [
  // { path: "/:domainName/:userId", element: <Lobby /> },
  { path: "/casino", element: <Lobby /> },
  { path: "/casino", element: <Lobby /> },
  { path: "/view-all/:provider/:category/:name", element: <ViewAll /> },
  // { path: "/:referralCode", element: <Lobby /> },
  { path: "/promotions", element: <Promotions /> },
  { path: "/liveCasino", element: <Livecasino /> },
  { path: "/game/:gameId", element: <IframeGame /> },
  { path: "/cms/:id/:name", element: <Cms /> },
  { path: "/user/forgotPassword", element: <ForgotPassword /> },
  { path: "/user/verifyEmail", element: <VerifyEmail /> },
  { path: "/blog", element: <Blog /> },
  { path: "/blog/:slug", element: <PostDetail /> },
];

export const createRoute = (path, component, privateRoute, props) => {
  if (privateRoute) {
    if (!session.getSessionCred) {
      return (
        <Route
          path={path}
          key={path}
          element={<Navigate replace to="/lobby" />}
        />
      );
    }
    return (
      <Route exact path={path} key={path} element={component} {...props} />
    );
  }
  return <Route exact path={path} key={path} element={component} {...props} />;
};

export const getApplicationRoutes = (routes) => {
  return routes.map((route) =>
    createRoute(route.path, route.element, route.private, route.props)
  );
};

export const buildRouter = (routesConfig, mapRoutes = getApplicationRoutes) => {
  const applicationRoutes = mapRoutes(routesConfig);
  return (
    <Router>
      <section className="main-section">
        <Header />
        <HideAppSidebar>
          <AppSidebar />
        </HideAppSidebar>
        <Toast />
        <Routes>
          {applicationRoutes}
          <Route path="/user" key={"user"} element={<Sidebar />}>
            <Route
              key={"MyProfile"}
              path={"myProfile"}
              element={<MyProfile />}
            />
            {/* <Route key={"transactions"} path={"transactions"} element={<Transactions />} /> */}
            <Route key={"Deposit"} path={"deposit"} element={<Deposit />} />
            <Route
              key={"Transaction"}
              path={"transaction"}
              element={<Transaction />}
            />
            <Route
              key={"ResetPassword"}
              path={"resetPassword"}
              element={<ResetPassword />}
            />
            {/* <Route
              key={"BetHistory"}
              path={"betHistory"}
              element={<BetHistory />}
            /> */}
            {/* <Route key={"referral"} path={"referral"} element={<Referral />} /> */}
            <Route key={"Withdraw"} path={"withdraw"} element={<Withdraw />} />
            {/* <Route key={"Bonus"} path={"bonus"} element={<Bonus />} /> */}
            {/* <Route key={"Referral"} path={"referral"} element={<Referral />} /> */}
          </Route>
          {/* <Route
            key={"Favorites"}
            path={"/casino/favorites"}
            element={<Favorites />}
          /> */}
          {
            //Below are the sport book routes
          }
          <Route path={"/"} element={<SportBook />}>
            <Route
              key={"/sportsbook/lobby"}
              path={"lobby"}
              element={<SportBookLobby />}
            />
            <Route
              key={"/sportbookLobby"}
              path={"sportbookLobby"}
              element={<SportBookLobby />}
            />

            <Route
              key={"/sportsbook/more-market"}
              path={"more-market"}
              element={<MoreMarkets />}
            />
            <Route
              key={"/sportsbook/my-bets"}
              path={"my-bets"}
              element={<Mybets />}
            />
            <Route
              key={"/sportsbook/favorites"}
              path={"favorites"}
              element={<Favorite />}
            />
            <Route
              key={"/sportsbook/results"}
              path={"results"}
              element={<MatchResults />}
            />
          </Route>
          <Route key={"/blog"} path={"/blog"} element={<Blog />} />
          <Route key={"notSub"} path={"*"} element={<NotASubscriber />} />
          <Route path="/blog/:slug" element={<PostDetail />} />
          <Route path="*" element={<NotASubscriber />} />
        </Routes>
      </section>
      <Footer />
    </Router>
  );
};

const appRouter = () => buildRouter(appRoutes);

export default appRouter;
