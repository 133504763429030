import React, { useEffect, useState } from "react";

import { Helmet } from "react-helmet-async";
import {
  Grid,
  // Typography,
  // Button,
  MenuItem,
  Select,
  Tabs,
  Tab,
  Box,
  Button,
  Skeleton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Carousel } from "react-responsive-carousel";
import { isMobile } from "react-device-detect";
import LazyLoad from "react-lazy-load";
import { useTranslation } from "react-i18next";

import SearchGames from "../SearchGames";
// import LiveWins from "../../Casino/LiveWins";
import useStyles from "./Lobby.styles";
// import { formatCategoryName } from "../../../utils/helper";
import {
  getLobbyBannersStart,
  getProvidersStart,
  getSubCategoryStart,
} from "../../../redux-saga/redux/casino/lobby/casinoLobby";
import CategoryGames from "./CategoryGames";
import Providers from "../Providers";
import { setReferralCode } from "../../../redux-saga/redux/auth/authentication";
import { getGamesStart } from "../../../redux-saga/redux/casino/lobby/casinoGames";
// import Favorites from "../Favorites";
import { homePageGamesLimit } from "../../../utils/constants";

import "react-responsive-carousel/lib/styles/carousel.min.css";

const Lobby = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isLoggedIn } = useSelector((state) => state.authentication);
  const { referralCode = null } = useParams();
  const [provider, setProvider] = useState(0);
  const [category, setCategory] = useState(0);
  const [selected, setSelected] = useState("all");
  const [filter, setFilterby] = useState("category");
  const { providers, subCategory, lobbyBannerList } = useSelector(
    (state) => state.casinoLobby
  );

  const numberOfSkeleton = Array.from({ length: 15 });

  useEffect(() => {
    if (isLoggedIn && filter === "category") {
      handleClick("", category, filter);
    } else {
      handleClick("", provider, filter);
    }
  }, [isLoggedIn]);
  useEffect(() => {
    if (referralCode) {
      dispatch(setReferralCode(referralCode));
    }
    dispatch(getSubCategoryStart());
    dispatch(getProvidersStart());
    dispatch(getLobbyBannersStart({ category: 1 }));
  }, [dispatch, referralCode]);

  const handleClick = (name, id, filterBy) => {
    // if (filterBy === "providers") {
    //   navigate(`/view-all/${id}/false/${name}`);
    // }
    // if (filterBy === "category") {
    //   navigate(`/view-all/false/${id}/${name}`);
    // }

    if (filterBy === "providers") {
      let reqBody = {
        providerId: id,
        isMobile,
        isHomePage: true,
        subCategoryId: category,
        limit: homePageGamesLimit,
      };
      if (!category || category === "all") delete reqBody.subCategoryId;
      if (!id || id === "all") delete reqBody.providerId;
      dispatch(getGamesStart({ ...reqBody }));
      setProvider(id);
      setFilterby("providers");
    }
    if (filterBy === "category") {
      let reqBody = {
        providerId: provider,
        isMobile,
        isHomePage: true,
        subCategoryId: id,
        limit: homePageGamesLimit,
      };
      if (!provider || provider === "all") delete reqBody.providerId;
      if (!id || id === "all") delete reqBody.subCategoryId;

      dispatch(getGamesStart({ ...reqBody }));
      setCategory(id);
      setFilterby("category");
    }
  };

  const handleChangeProvider = (event) => {
    setProvider(event.target.value);
  };

  // const handleChangeCategory = (event) => {
  //   setCategory(event.target.value);
  // };
  const getCrouselParams = () => {
    const sameConfig = {
      autoPlay: false,
      infiniteLoop: false,
      showThumbs: false,
      showStatus: false,
      interval: 4000,
    };
    if (lobbyBannerList?.length > 1) {
      return {
        ...sameConfig,
        renderArrowPrev: (clickHandler) => {
          return isMobile ? null : (
            <Button className={classes.sliderPrev} onClick={clickHandler}>
              <img src="/images/left.png" alt="" />
            </Button>
          );
        },
        renderArrowNext: (clickHandler) => {
          return isMobile ? null : (
            <Button onClick={clickHandler} className={classes.sliderNext}>
              <img src="/images/right.png" alt="" />
            </Button>
          );
        },
      };
    } else {
      return {
        ...sameConfig,
        renderArrowPrev: () => {},
        renderArrowNext: () => {},
        showIndicators: false,
      };
    }
  };
  return (
    <div className={classes.wrapper}>
      <Helmet>
        <title>
          Casino Games Online for Real Money Betting |Bet World Sports
        </title>
        <meta
          name="description"
          content="Play online casino games at Bet World Sports and win real money! Enjoy a wide variety of games, secure transactions, and 24/7 support. Join now!"
        />
      </Helmet>
      <Grid className={classes.homeBanner}>
        <Carousel {...getCrouselParams()}>
          {lobbyBannerList?.length &&
            lobbyBannerList.map((banner, i) => {
              return (
                <LazyLoad key={i}>
                  <img
                    src={banner?.image_url}
                    alt="Banner img"
                    className={classes.bannerImg}
                  />
                </LazyLoad>
              );
            })}
        </Carousel>
        {/* <Grid className={classes.bannerContent}> */}
        {/* <Typography variant="h1" gutterBottom>
            Reload Bonus for <br />
            Each Deposit
          </Typography> */}
        {/* <Grid className={classes.bannerContentSubText}> */}
        {/* <div className={classes.bannerContent}>
          <img src="/images/banner-text.svg" alt="Banner content" />
        </div> */}

        {/* <img
          src="/images/banner.png"
          alt="Banner img"
          className={classes.bannerImg}
        /> */}
        {/* <Typography> will increase any Deposit!</Typography> */}
        {/* </Grid> */}
        {/* <Grid className={classes.btnWrap}>
            <Button className={classes.btnPrimary}>Get</Button>
          </Grid> */}
        {/* </Grid> */}
      </Grid>

      {/* <div className={classes.centerLogo}>
        <img src="/images/center-logo.svg" alt="center  logo" />
      </div> */}

      {/* <LiveWins /> */}

      <Box className={classes.homeFilter}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} lg={9}>
            {subCategory?.length <= 0 ? (
              <Tabs
                indicatorColor="none"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                className={classes.tabWrapper}
              >
                {numberOfSkeleton.map((index) => (
                  <Skeleton
                    key={index}
                    variant="rounded"
                    className={classes.skeleton}
                    animation="pulse"
                  />
                ))}
              </Tabs>
            ) : (
              <Tabs
                indicatorColor="none"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                className={classes.tabWrapper}
              >
                <Tab
                  onClick={() => {
                    setSelected("all");
                    handleClick("all", "all", "category");
                  }}
                  className={`${classes.categoryTab} ${
                    "all" === selected ? "active" : null
                  }`}
                  disableRipple={true}
                  value={"all"}
                  key={"all"}
                  label={"all"}
                />
                {subCategory.map((item, val) => (
                  <Tab
                    onClick={() => {
                      setSelected(val);
                      handleClick(
                        item.name,
                        item.masterGameSubCategoryId,
                        "category"
                      );
                    }}
                    className={`${classes.categoryTab} ${
                      val === selected ? "active" : null
                    }`}
                    disableRipple={true}
                    value={item.name}
                    key={item.masterGameSubCategoryId}
                    label={item.name}
                  />
                ))}
              </Tabs>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            md={12}
            lg={3}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={provider}
              disabled={!(providers?.length > 0)}
              variant="outlined"
              className={`${classes.selectTab} ${
                provider !== 0 ? "active" : null
              }`}
              onChange={handleChangeProvider}
            >
              <MenuItem
                value={0}
                onClick={() => {
                  if (provider !== 0) {
                    handleClick("all", "all", "providers");
                  }
                }}
              >
                {t("COMMON.PROVIDERS")}
              </MenuItem>
              {providers?.length > 0 &&
                providers.map((item) => (
                  <MenuItem
                    onClick={() =>
                      handleClick(
                        item.name,
                        item.masterCasinoProviderId,
                        "providers"
                      )
                    }
                    key={item.name}
                    value={item.masterCasinoProviderId}
                  >
                    {item.name}
                  </MenuItem>
                ))}
            </Select>

            <Grid className={classes.inputBox}>
              <SearchGames />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {/* <Favorites /> */}
      <CategoryGames selectedCategory={selected} selectedProvider={provider} />
      <Providers />
    </div>
  );
};

export default Lobby;
